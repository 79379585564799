import {Breadcrumbs} from "../layout/breadcrumbs";
import {Link} from "react-router-dom";

export const NotFound = () => {
	return (
		<>
			<Breadcrumbs page={"Page Not Found"}/>
			<section id="error_main_area" className="section_padding">
				<div className="container">
				    <div className="row">
					    <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
						    <div className="error_inner">
								<img src="/img/common/error.png" alt="img"/>
								<h2>The page you requested could not be found</h2>
								<Link to="/" className="btn btn_theme btn_md">Go back home</Link>
						    </div>
					    </div>
				    </div>
				</div>
			</section>
		</>
	);
}