import React from 'react';
import {Route, Routes} from "react-router";
import {Home} from "./pages/home";
import {Footer} from "./layout/footer";
import {Header} from "./layout/header";
import {About} from "./pages/about";
import {AvailableDogs} from "./pages/available-dogs";
import {HowToAdopt} from "./pages/how-to-adopt";
import {Gallery} from "./pages/gallery";
import {FAQs} from "./pages/faqs";
import {Stories} from "./pages/stories";
import {Contact} from "./pages/contact";
import {NotFound} from "./pages/not-found";

function App() {
    return (
        <>
            <Header/>
            <Routes>
                <Route path={"/"} element={<Home/>}/>
                <Route path={"/about"} element={<About/>}/>
                {/*<Route path={"/available-dogs"} element={<AvailableDogs/>}/>*/}
                <Route path={"/how-to-adopt"} element={<HowToAdopt/>}/>
                <Route path={"/gallery"} element={<Gallery/>}/>
                <Route path={"/faqs"} element={<FAQs/>}/>
                <Route path={"/stories"} element={<Stories/>}/>
                <Route path={"/contact"} element={<Contact/>}/>
                <Route path={"*"} element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

export default App;
