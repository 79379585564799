import React, {FC, useEffect, useState} from 'react';
// @ts-ignore
import Modal from 'react-modal';
// @ts-ignore
import QRCode from 'qrcode.react';
import {config} from "../config";

interface PaymentModalProps {
	isOpen: boolean;
	onClose: () => void;
}

enum CoinType {
	Bitcoin = 'Bitcoin',
}

type Coin = Record<CoinType, {
	address: string;
	network: string;
}>;

export const PaymentModal: FC<PaymentModalProps> = ({ isOpen, onClose }) => {
	const [selectedPayment, setSelectedPayment] = useState('Crypto');
	const [selectedCoin, setSelectedCoin] = useState<CoinType>(CoinType.Bitcoin);
	const [paymentConfirmed, setPaymentConfirmed] = useState(false);

	const coins: Coin = {
		[CoinType.Bitcoin]: {
			address: 'bc1q7vhhw8vf4k66y40yetrv5g97m9p8c7lzcavajj',
			network: 'BTC',
		},
	};

	const coinOptions = Object.keys(coins);

	const handlePaymentConfirmed = () => {
		setPaymentConfirmed(true);
		setTimeout(() => {
			onClose();
			setPaymentConfirmed(false);
		}, 3000);
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel="Payment Modal"
			style={{
				overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
				content: {
					backgroundColor: 'rgba(248, 248, 255, 0.9)',
					color: '#F34100',
					top: '50%',
					left: '50%',
					right: 'auto',
					bottom: 'auto',
					marginRight: '-50%',
					transform: 'translate(-50%, -50%)',
					padding: '20px',
					borderRadius: '8px',
					width: '90%',
					maxWidth: '500px',
					textAlign: 'center',
				},
			}}
		>
			<button onClick={onClose} style={{ float: 'right', background: 'none', border: 'none', fontSize: '20px' }}>×</button>
			{!paymentConfirmed ? (
				<>
					<h2 style={{ color: '#F34100' }}>Choose Payment Method</h2>
					<div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
						<button
							onClick={() => setSelectedPayment('Crypto')}
							style={{
								marginRight: '10px',
								padding: '10px 20px',
								backgroundColor: selectedPayment === 'Crypto' ? '#F34100' : 'white',
								color: selectedPayment === 'Crypto' ? 'white' : '#F34100',
								border: '1px solid #F34100',
								borderRadius: '5px',
								flex: 1,
							}}
						>
							Crypto
						</button>
						<button
							onClick={() => setSelectedPayment('Card')}
							style={{
								padding: '10px 20px',
								backgroundColor: selectedPayment === 'Card' ? '#F34100' : 'white',
								color: selectedPayment === 'Card' ? 'white' : '#F34100',
								border: '1px solid #F34100',
								borderRadius: '5px',
								flex: 1,
							}}
						>
							Card
						</button>
					</div>
					{selectedPayment === 'Crypto' && (
						<div style={{ marginTop: '20px' }}>
							<label htmlFor="coin-select" style={{ display: 'block', marginBottom: '10px' }}>Select Coin:</label>
							<select
								id="coin-select"
								value={selectedCoin}
								onChange={(e) => setSelectedCoin(() => e.target.value as CoinType)}
								style={{ padding: '10px', borderRadius: '5px', border: '1px solid #F34100', marginBottom: '20px', width: '100%' }}
							>
								{coinOptions.map((coin) => (
									<option key={coin} value={coin}>{coin}</option>
								))}
							</select>
							<div>
								<p>Wallet Address: {coins[selectedCoin].address}</p>
								<p>Network: {coins[selectedCoin].network}</p>
								<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
									<QRCode value={coins[selectedCoin].address} />
								</div>
								<button
									onClick={handlePaymentConfirmed}
									style={{
										marginTop: '20px',
										padding: '10px 20px',
										backgroundColor: '#F34100',
										color: 'white',
										border: 'none',
										borderRadius: '5px',
										cursor: 'pointer',
										width: '100%',
									}}
								>
									I have paid
								</button>
							</div>
						</div>
					)}
					{selectedPayment === 'Card' && (
						<div style={{ marginTop: '20px' }}>
							<p style={{ color: 'red' }}>This payment option isn't available at the moment. Please contact the support team ({config.appEmail}) if you wish to continue.</p>
						</div>
					)}
				</>
			) : (
				<div style={{ textAlign: 'center', marginTop: '50px' }}>
					<div style={{ fontSize: '50px', color: 'green' }}>✔</div>
					<p style={{ fontSize: '20px', color: 'green' }}>Thank you for your donation!</p>
				</div>
			)}
		</Modal>
	);
};
