import {Link} from "react-router-dom";
import {config} from "../config";

export const Footer = () => {
	const scrollToTop = () => window.scrollTo(0, 0);

	return (
	  <>
		  <footer id="footer_area">
			  <div className="container">
				  <div className="row">
					  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
						  <div className="footer_area_about">
							  <img src="/img/logo.png" alt="img"/>
							  <p>Our mission is to rescue, rehabilitate, and rehome dogs in need, providing them with
								  the care and love they deserve. Join us in making a difference in the lives of these
								  wonderful animals.</p>
						  </div>
					  </div>
					  <div className="col-lg-2 col-md-6 col-sm-12 col-12">
						  <div className="footer_navitem_ara">
							  <h3>Adoption</h3>
							  <div className="nav_item_footer">
							  <ul>
									  {/*<li><Link to="/available-dogs">Available Dogs</Link></li>*/}
									  <li><Link to="/how-to-adopt">How To Adopt</Link></li>
									  <li><Link to="/faqs">FAQs</Link></li>
									  <li><Link to="/stories">Stories</Link></li>
									  <li><Link to="/gallery">Gallery</Link></li>
								  </ul>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-2 col-md-6 col-sm-12 col-12">
						  <div className="footer_navitem_ara">
							  <h3>Quick links</h3>
							  <div className="nav_item_footer">
								  <ul>
									  <li><Link to="/">Home</Link></li>
									  <li><Link to="/about">About us</Link></li>
									  {/*<li><Link to="/shop">Shop</Link></li>*/}
									  <li><Link to="/contact">Contact us</Link></li>
								  </ul>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
						  <div className="footer_navitem_ara">
							  <h3>Get in touch</h3>
							  <h4>
								  <strong>Email:</strong> <Link
								  to={`mailto:${config.appEmail}`}>{config.appEmail}</Link>
							  </h4>
							  <h4>
								  <strong>Phone:</strong> <Link
								  to={`tel:${config.appPhone}`}>{config.appPhone}</Link>
							  </h4>
							  <h4>
								  <strong>Addresses:</strong>
								  <br/>2911 47th Ave N, St. Petersburg, FL 33714, United States
								  <br/>144 Main St. Unit O, East Hartford, CT 06118, United States
								  <br/>71 Oglethorpe Street, NW, Washington DC 20011, United States
							  </h4>
						  </div>
					  </div>
				  </div>
			  </div>
		  </footer>
		  <div className="copyright_area">
			  <div className="container">
			  <div className="row align-items-center">
					  <div className="col-12">
						  <div className="text-center">
							  <p style={{ color: "white" }}>Copyright © {new Date().getFullYear()}. All Rights Reserved</p>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
		  <div className="go-top" onClick={scrollToTop}>
			  <i className="fas fa-chevron-up"></i>
			  <i className="fas fa-chevron-up"></i>
		  </div>
		  <div className="offcanvas offcanvas-end offcanvas_custom" tabIndex={-1} id="offcanvasRight">
			  <div className="offcanvas-header">
				  <img src="/img/logo.png" alt="img"/>
				  <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			  </div>
			  <div className="offcanvas-body">
				  <div className="offcanvas_right_wrapper">
					  <h4>Contact Us</h4>
					  <div className="top_bar_left_item">
						  <div className="top_Bar_left_icon">
							  <i className="fas fa-envelope"></i>
						  </div>
						  <div className="top_Bar_left_text">
							  <h5><Link to={`mailto:${config.appEmail}`}>{config.appEmail}</Link></h5>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </>
	)
}
