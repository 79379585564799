import {FC} from "react";

interface IProps {
	page: string;
}

export const Breadcrumbs: FC<IProps> = (props) => {
  return (
	  <section id="common_area_banner">
		  <div className="container">
			  <div className="row">
				  <div className="col-lg-12">
					  <div className="common_banner_content">
						  <h2>{props.page}</h2>
						  <ul>
							  <li><a href="/">Home</a></li>
							  <li><span>/</span> {props.page}</li>
						  </ul>
					  </div>
				  </div>
			  </div>
		  </div>
	  </section>
  );
}