import {Breadcrumbs} from "../layout/breadcrumbs";

export const FAQs = () => {
  return (
	  <>
		  <Breadcrumbs page={"Frequently Asked Questions"}/>
		  <section id="main_service_details" className="section_padding">
			  <div className="container">
				  <div className="row">
					  <div className="col-lg-9">
						  <div className="service_details_left_wrapper">
							  <div className="service_details_left_top">
								  <h2>Frequently Asked Questions</h2>
								  <p>Got questions about pet care, adoption, or our services? Find answers to commonly
									  asked questions below.</p>
							  </div>
							  <div className="service_details_left_bottom">
								  <div className="row">
									  <div className="col-lg-12">
										  <div className="accordion" id="accordionExample">
											  <div className="accordion-item">
												  <h2 className="accordion-header" id="headingOne">
													  <button className="accordion-button" type="button"
													          data-bs-toggle="collapse" data-bs-target="#collapseOne"
													          aria-expanded="true" aria-controls="collapseOne">
														  How do I prepare my home for a new pet?
													  </button>
												  </h2>
												  <div id="collapseOne" className="accordion-collapse collapse show"
												       aria-labelledby="headingOne" data-bs-parent="#accordionExample">
													  <div className="accordion-body">
														  <p>Bringing a new pet home is an exciting time, but it's
															  important to make sure your home is safe and welcoming.
															  Here are some tips for preparing your home for your new
															  furry friend:</p>
														  <ul>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Secure any hazardous items or chemicals out of
																  reach.
															  </li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Set up a cozy bed or sleeping area.</li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Stock up on food, water bowls, and toys.</li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Create a designated potty area if needed.</li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Introduce your new pet to their surroundings
																  gradually.
															  </li>
														  </ul>
													  </div>
												  </div>
											  </div>
											  <div className="accordion-item">
												  <h2 className="accordion-header" id="headingTwo">
													  <button className="accordion-button collapsed" type="button"
													          data-bs-toggle="collapse" data-bs-target="#collapseTwo"
													          aria-expanded="false" aria-controls="collapseTwo">
														  What vaccinations does my pet need?
													  </button>
												  </h2>
												  <div id="collapseTwo" className="accordion-collapse collapse"
												       aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
													  <div className="accordion-body">
														  <p>Vaccinations are essential for keeping your pet healthy and
															  protected from common diseases. The specific vaccines your
															  pet needs depend on factors such as their age, lifestyle,
															  and medical history. Common vaccinations for dogs include
															  rabies, distemper, and parvovirus, while cats may need
															  vaccines for rabies, feline leukemia, and distemper.
															  Consult with your veterinarian to create a vaccination
															  schedule tailored to your pet's needs.</p>
													  </div>
												  </div>
											  </div>
											  <div className="accordion-item">
												  <h2 className="accordion-header" id="headingThree">
													  <button className="accordion-button collapsed" type="button"
													          data-bs-toggle="collapse" data-bs-target="#collapseThree"
													          aria-expanded="false" aria-controls="collapseThree">
														  How can I help my pet adjust to a new environment?
													  </button>
												  </h2>
												  <div id="collapseThree" className="accordion-collapse collapse"
												       aria-labelledby="headingThree"
												       data-bs-parent="#accordionExample">
													  <div className="accordion-body">
														  <p>Adjusting to a new environment can be stressful for pets,
															  but there are steps you can take to help them feel more
															  comfortable:</p>
														  <ul>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Give them time to explore their new surroundings at
																  their own pace.
															  </li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Stick to a routine for feeding, playtime, and
																  exercise.
															  </li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Provide plenty of affection and reassurance.</li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Gradually introduce them to new people and pets.</li>
															  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Consider using calming aids or pheromone products.
															  </li>
														  </ul>
													  </div>
												  </div>
											  </div>
										  </div>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
	  </>
  );
}
