import {Breadcrumbs} from "../layout/breadcrumbs";
import {Link} from "react-router-dom";

export const About = () => {
  return (
	  <>
		  <Breadcrumbs page={"About Us"}/>
		  <section id="about_area" className="section_padding">
			  <div className="container">
				  <div className="row">
					  <div className="col-lg-6">
						  <div className="about_area_left_wrapper">
							  <div className="about_left_content_top">
								  <h3><img src="/img/icon/sq-dot.png" alt="img"/> About Us</h3>
								  <h2>We Care Deeply About Your Pets</h2>
								  <p>
									  At Humans Love Animals, we believe every pet deserves love, care, and the best services
									  available. Our dedicated team is committed to providing top-notch care for your
									  pets, from grooming to adoption assistance.
								  </p>
								  <div className="about_round_check_wrapper">
									  <div className="about_round_check_item">
										  <div className="about_round_check_inner">
											  <img src="/img/icon/round-check.png" alt="icon"/>
											  <p>Certified Groomers</p>
										  </div>
										  <div className="about_round_check_inner">
											  <img src="/img/icon/round-check.png" alt="icon"/>
											  <p>Comprehensive Pet Care</p>
										  </div>
									  </div>
									  <div className="about_round_check_item">
										  <div className="about_round_check_inner">
											  <img src="/img/icon/round-check.png" alt="icon"/>
											  <p>Passionate Animal Lovers</p>
										  </div>
										  <div className="about_round_check_inner">
											  <img src="/img/icon/round-check.png" alt="icon"/>
											  <p>Support for Pet Adoption</p>
										  </div>
									  </div>
								  </div>
							  </div>
							  <div className="about_left_content_bottom">
								  <div className="accordion" id="accordionExample">
									  <div className="accordion-item">
										  <h2 className="accordion-header" id="headingOne">
											  <button className="accordion-button" type="button"
											          data-bs-toggle="collapse"
											          data-bs-target="#collapseOne" aria-expanded="true"
											          aria-controls="collapseOne">
												  Why Choose Us?
											  </button>
										  </h2>
										  <div id="collapseOne" className="accordion-collapse collapse show"
										       aria-labelledby="headingOne" data-bs-parent="#accordionExample">
											  <div className="accordion-body">
												  <p>
													  We provide a range of services tailored to meet the unique needs
													  of each pet. Our team uses the latest techniques in pet care to
													  ensure your pet's health and happiness.
												  </p>
											  </div>
										  </div>
									  </div>
									  <div className="accordion-item">
										  <h2 className="accordion-header" id="headingTwo">
											  <button className="accordion-button collapsed" type="button"
											          data-bs-toggle="collapse" data-bs-target="#collapseTwo"
											          aria-expanded="false" aria-controls="collapseTwo">
												  Our Services
											  </button>
										  </h2>
										  <div id="collapseTwo" className="accordion-collapse collapse"
										       aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
											  <div className="accordion-body">
												  <p>
													  From expert grooming to adoption guidance and veterinary care,
													  discover the comprehensive services we offer to support your pet
													  at every stage.
												  </p>
											  </div>
										  </div>
									  </div>
									  <div className="accordion-item">
										  <h2 className="accordion-header" id="headingThree">
											  <button className="accordion-button collapsed" type="button"
											          data-bs-toggle="collapse" data-bs-target="#collapseThree"
											          aria-expanded="false" aria-controls="collapseThree">
												  Community and Support
											  </button>
										  </h2>
										  <div id="collapseThree" className="accordion-collapse collapse"
										       aria-labelledby="headingThree" data-bs-parent="#accordionExample">
											  <div className="accordion-body">
												  <p>
													  Learn about our community efforts, educational programs, and how
													  we work to make a difference in the lives of pets and their
													  owners.
												  </p>
											  </div>
										  </div>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-6">
						  <div className="about_area_right_img">
							  <img src="/img/common/about.png" alt="img"/>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
		  <section id="our_consultation_area" className="section_padding bg_section">
			  <div className="container">
				  <div className="row">
					  <div className="col-lg-6 offset-lg-3">
						  <div className="section_heading">
							  <h2>Consultation Services</h2>
							  <p>We offer personalized consultation to help you understand the responsibilities and joy
								  of adopting a dog. Our team is here to answer all your questions and guide you through
								  the adoption process.</p>
						  </div>
					  </div>
				  </div>
				  <div className="row">
					  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
						  <div className="our_consultation_item">
							  <img src="/img/icon/consultation-1.png" alt="Get in touch"/>
							  <h3>Get in touch</h3>
							  <p>Reach out to us to discuss how you can help a dog find a loving home. We're available
								  via phone, email, or in-person visits.</p>
							  <img src="/img/element/arrow-down.png" alt="Arrow down" className="arrow_down_consult"/>
						  </div>
					  </div>
					  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
						  <div className="our_consultation_item">
							  <img src="/img/icon/consultation-2.png" alt="Make a schedule"/>
							  <h3>Make a schedule</h3>
							  <p>Schedule a visit to meet our dogs. We can arrange a time for you to come and meet
								  potential furry companions.</p>
							  <img src="/img/element/arrow-up.png" alt="Arrow up" className="arrow_up_consult"/>
						  </div>
					  </div>
					  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
						  <div className="our_consultation_item">
							  <img src="/img/icon/consultation-3.png" alt="Pet admission"/>
							  <h3>Pet Admission</h3>
							  <p>If you need to surrender a dog, our admission process ensures they are cared for and
								  rehomed responsibly.</p>
							  <img src="/img/element/arrow-down.png" alt="Arrow down" className="arrow_down_consult"/>
						  </div>
					  </div>
					  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
						  <div className="our_consultation_item">
							  <img src="/img/icon/consultation-4.png" alt="Cure your pet"/>
							  <h3>Care Consultations</h3>
							  <p>Get expert advice on pet care, health issues, and how to provide the best environment
								  for your new companion.</p>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
		  <section id="our_groomers_area" className="section_padding">
			  <div className="container">
				  <div className="row">
					  <div className="col-lg-6 offset-lg-3">
						  <div className="section_heading">
							  <h2>Meet Our Team</h2>
							  <p>Our team of skilled specialists are dedicated to providing your pet with the best care. Get to know the professionals who will be looking after your pet's grooming needs.</p>
						  </div>
					  </div>
				  </div>
				  <div className="row">
					  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
						  <div className="groomers_area_wrapper">
							  <div className="groomers_area_img img_animation_one">
								  <img src="/img/team/martinez.jpeg" alt="Jason Modrich" style={{height: "400px"}}/>
							  </div>
							  <div className="groomers_area_decantation">
								  {/*<div className="groomers_social_icon">
									  <ul>
										  <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
									  </ul>
								  </div>*/}
								  <h3>Laura Martinez</h3>
								  <p>
									  Meet Laura Martinez, a passionate director that have dedicated her life to saving
									  and improving the lives of animals in need. With a background in animal welfare
									  and a heart for compassion, Laura leads her team with empathy and determination.
									  From coordinating rescue missions to advocating for animal rights, she's a driving
									  force behind the organization's mission to create a world where every animal is
									  valued and loved.
								  </p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
						  <div className="groomers_area_wrapper">
							  <div className="groomers_area_img img_animation_one">
								  <img src="/img/team/neyman.jpeg" alt="Jesica Lawrence" style={{height: "400px"}}/>
							  </div>
							  <div className="groomers_area_decantation">
								  {/*<div className="groomers_social_icon">
									  <ul>
										  <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
									  </ul>
								  </div>*/}
								  <h3>Dr. Dennis Neyman</h3>
								  <p>
									  Introducing Dr. Dennis Neyman, a dedicated traveling veterinarian. With a passion
									  for both animals and travel, Dr. Dennis spends his days traversing the globe,
									  providing essential veterinary services to animals in need. From performing
									  surgeries in makeshift clinics to administering vaccinations in rural villages,
									  he is a tireless advocate for animal welfare, bringing hope and healing wherever
									  his journey takes him.
								  </p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
						  <div className="groomers_area_wrapper">
							  <div className="groomers_area_img img_animation_one">
								  <img src="/img/team/reynolds.jpeg" alt="Fredrich Monn" style={{height: "400px"}}/>
							  </div>
							  <div className="groomers_area_decantation">
								  {/*<div className="groomers_social_icon">
									  <ul>
										  <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
									  </ul>
								  </div>*/}
								  <h3>Dr. Michael Reynolds</h3>
								  <p>
									  Meet Dr. Michael Reynolds, a compassionate veterinarian dedicated to serving
									  animals in need through his work with human loves animals rescue organisation.
									  With a lifelong passion for animals and a commitment to their well-being,
									  Dr. Reynolds goes above and beyond to provide medical care and rehabilitation to
									  rescued animals. Whether he's performing surgeries, treating injuries, or simply
									  offering a comforting touch, Dr. Reynolds is a trusted ally for animals in crisis,
									  embodying the spirit of compassion and dedication in his every action.
								  </p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
						  <div className="groomers_area_wrapper">
							  <div className="groomers_area_img img_animation_one">
								  <img src="/img/team/lamb.jpeg" alt="Tubic Murphy" style={{height: "400px"}}/>
							  </div>
							  <div className="groomers_area_decantation">
								  {/*<div className="groomers_social_icon">
									  <ul>
										  <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-linkedin"></i></Link></li>
									  </ul>
								  </div>*/}
								  <h3>Dr. Scott Lamb</h3>
								  <p>
									  Introducing Dr. Scott Lamb, a caring veterinarian whose love for animals drives
									  his work with human loves animals rescue organisation. With a gentle touch and a
									  wealth of medical expertise, Dr. Scott dedicates his days to providing quality
									  care to animals in need. Whether he's performing routine check-ups, treating
									  injuries, or offering compassionate end-of-life care, he's committed to improving
									  the lives of every furry patient that crosses his path. Dr. Scott's unwavering
									  dedication to animal welfare makes him a valued asset to the rescue organization
									  and a beloved figure in the community.
								  </p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
						  <div className="groomers_area_wrapper">
							  <div className="groomers_area_img img_animation_one">
								  <img src="/img/team/bakker.jpeg" alt="David Bakker" style={{height: "400px"}}/>
							  </div>
							  <div className="groomers_area_decantation">
								  {/*<div className="groomers_social_icon">
									  <ul>
										  <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
										  <li><Link to="#"><i className="fab fa-linkedin"></i></Link></li>
									  </ul>
								  </div>*/}
								  <h3>Dr. David Bakker</h3>
								  <p>
									  Dr. David Bakker is a dedicated veterinarian renowned for his unwavering
									  commitment to assisting animals affected by disasters. With over three decades
									  of experience in veterinary medicine, he has become a leading advocate for animal
									  welfare during crises. Driven by compassion and expertise, he has worked
									  tirelessly in numerous disaster zones, providing essential medical care and
									  rehabilitation to countless animals in need. His passion extends beyond immediate
									  relief efforts; he actively educates communities on disaster preparedness and the
									  importance of animal welfare. Dr. Bakker's profound dedication to this cause has
									  not only saved lives but has also inspired others to support and prioritize the
									  well-being of animals in times of adversity.
								  </p>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
	  </>
  )
}
