import {Breadcrumbs} from "../layout/breadcrumbs";
import {config} from "../config";
import {Link} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";

export const Contact = () => {
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [message, setMessage] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(() => {
		if (isError || isSuccess) {
			let timeoutID = setTimeout(() => {
				if (isError) {
					setIsError(false);
				}
				if (isSuccess) {
					setIsSuccess(false);
				}
			}, 3000);
			return () => clearTimeout(timeoutID);
		}
	}, [isError, isSuccess]);

	const handleSubmit = async () => {
		try {
			setIsSubmitting(true);
			const {data} = await axios.post("https://formsubmit.co/ajax/info@humansloveanimals.org", {
				_subject: "Contact Form | Humans Love Animals",
				Email: email,
				Name: name,
				Message: message,
			});
			if (data.success) {
				setIsSuccess(true);
			} else {
				setIsError(true);
			}
			console.log(data);
		} catch (error) {
			setIsError(true);
			console.log(error);
		} finally {
			setIsSubmitting(false);
		}
	}
  return (
	  <>
		  <Breadcrumbs page={"Contact Us"}/>
		  <section id="contact_main_card_area" className="section_padding">
			  <div className="container">
				  <div className="row justify-content-center">
					  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
						  <div className="contact_card_item">
							  <img src="/img/icon/contact-2.png" alt="icon"/>
							  <h3>Email</h3>
							  <ul>
								  <li><Link to={`mailto:${config.appEmail}`}>{config.appEmail}</Link></li>
							  </ul>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
		  <section id="contact_main_form_area" className="section_padding_bottom">
			  <div className="container">
				  <div className="row justify-content-center">
					  <div className="col-lg-6">
						  <div className="contact_form_left_side">
							  <div className="contact_form_heading">
								  <h2>Contact Us</h2>
								  <p>If you have any questions or inquiries, feel free to reach out to us. We're here to
									  help!</p>
							  </div>
							  <div className="contact_form_main_area">
								  <form id="contact_form" onSubmit={e => {
									  e.preventDefault();
									  handleSubmit();
								  }}>
									  <div className="row">
										  <div className="col-lg-6">
											  <div className="form-group">
												  <input type="text" className="form-control" value={name}
												         onChange={(e) => setName(e.target.value)}
												         placeholder="Enter full name" required/>
											  </div>
										  </div>
										  <div className="col-lg-6">
											  <div className="form-group">
												  <input type="text" className="form-control" value={email}
												         onChange={e => setEmail(e.target.value)}
												         placeholder="Enter email address" required/>
											  </div>
										  </div>
										  <div className="col-lg-12">
											  <div className="form-group">
												  <textarea rows={5} placeholder="Write your message" value={message}
												            onChange={(e) => setMessage(e.target.value)}
												            className="form-control" required/>
											  </div>
										  </div>
										  <div className="col-lg-12">
											  {isSuccess && (
												  <div className="alert alert-success" role="alert">
													  Message sent successfully!
												  </div>
											  )}
											  {isError && (
												  <div className="alert alert-danger" role="alert">
													  Message failed to send! Please try again later.
												  </div>
											  )}
										  </div>
										  <div className="col-lg-12">
											  <div className="submit_btn">
											  <button className="btn btn_theme btn_md">
													  {isSubmitting ? 'Sending message...' : 'Contact us'}
												  </button>
											  </div>
										  </div>
									  </div>
								  </form>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
	  </>
  );
}
