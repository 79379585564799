import {Breadcrumbs} from "../layout/breadcrumbs";

export const Stories = () => {
  return (
	  <>
		  <Breadcrumbs page={"Stories"}/>
		  <section id="testimonial_area" className="section_padding_bottom main_test_style">
			  <div className="container">
				  <div className="row">
					  <div className="col-lg-6 offset-lg-3">
						  <div className="section_heading">
							  <h2>Client Reviews</h2>
							  <p>Discover what our satisfied clients have to say about their experiences with our pet
								  care services.</p>
						  </div>
					  </div>
				  </div>
				  <div className="row">
					  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
						  <div className="testimonial_area_item">
							  <p className="test_main_para">"I adopted a cat from Humans Love Animals, and I couldn't be
								  happier! The staff was friendly and helpful throughout the process."</p>
							  <img src="/img/testimonial/quote.png" className="test_quote_img" alt="Quotation icon"/>
							  <div className="test_destination">
								  <h3>Emily Johnson</h3>
								  <p>Pet Owner 😁</p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
						  <div className="testimonial_area_item">
							  <p className="test_main_para">"I've been bringing my dog to Humans Love Animals for
								  grooming for years. They always do an amazing job!"</p>
							  <img src="/img/testimonial/quote.png" className="test_quote_img" alt="Quotation icon"/>
							  <div className="test_destination">
								  <h3>Michael Smith</h3>
								  <p>Pet Owner 😁</p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
						  <div className="testimonial_area_item">
							  <p className="test_main_para">"I appreciate the care and attention to detail the staff at
								  Humans Love Animals provide. My pets always come home happy and healthy!"</p>
							  <img src="/img/testimonial/quote.png" className="test_quote_img" alt="Quotation icon"/>
							  <div className="test_destination">
								  <h3>Jessica Wilson</h3>
								  <p>Pet Owner 😁</p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
						  <div className="testimonial_area_item">
							  <p className="test_main_para">"Humans Love Animals is my go-to place for all my pet's
								  needs. From grooming to veterinary care, they offer top-notch service!"</p>
							  <img src="/img/testimonial/quote.png" className="test_quote_img" alt="Quotation icon"/>
							  <div className="test_destination">
								  <h3>Sarah Thompson</h3>
								  <p>Pet Owner 😁</p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
						  <div className="testimonial_area_item">
							  <p className="test_main_para">"The team at Humans Love Animals truly cares about the
								  well-being of pets. I highly recommend their services to all pet owners!"</p>
							  <img src="/img/testimonial/quote.png" className="test_quote_img" alt="Quotation icon"/>
							  <div className="test_destination">
								  <h3>David Clark</h3>
								  <p>Pet Owner 😁</p>
							  </div>
						  </div>
					  </div>
					  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
						  <div className="testimonial_area_item">
							  <p className="test_main_para">"I've had nothing but positive experiences with Humans Love
								  Animals. Their knowledgeable staff always goes above and beyond!"</p>
							  <img src="/img/testimonial/quote.png" className="test_quote_img" alt="Quotation icon"/>
							  <div className="test_destination">
								  <h3>Amy Roberts</h3>
								  <p>Pet Owner 😁</p>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
	  </>
  );
}
