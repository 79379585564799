import {Link, useLocation} from "react-router-dom";
import {config} from "../config";
import {useCallback, useState} from "react";
import {PaymentModal} from "../pages/payment-modal";

export const Header = () => {
	const location = useLocation();

	const setActive = useCallback((path: string): "active" | "" => {
		return location.pathname === path ? "active" : "";
	}, [location.pathname]);

	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showPaymentModal, setShowPaymentModal] = useState(false);

	const MobileMenu = useCallback(() => {
		return (
			<div className={`mobile-menu ${showMobileMenu ? 'open' : ''}`}>
				<div className="menu-content">
					<button className="close-button" onClick={() => setShowMobileMenu(false)}>
						<div className="top_Bar_left_icon">
							<i className="fas fa-times"/>
						</div>
					</button>
					<ul>
						<li className="nav-item">
							<Link to="/" className={`nav-link ${setActive("/")}`}>Home</Link>
						</li>
						<li className="nav-item">
							<Link to="/about" className={`nav-link ${setActive("/about")}`}>About us</Link>
						</li>
						<li className="nav-item">
							<Link to="/how-to-adopt" className={`nav-link ${setActive("/how-to-adopt")}`}>How
								to Adopt</Link>
						</li>
						<li className="nav-item">
							<Link to="/gallery" className={`nav-link ${setActive("/gallery")}`}>Gallery</Link>
						</li>
						<li className="nav-item">
							<Link to="/faqs" className={`nav-link ${setActive("/faqs")}`}>FAQs</Link>
						</li>
						<li className="nav-item">
							<Link to="/stories" className={`nav-link ${setActive("/stories")}`}>Stories</Link>
						</li>
						<li className="nav-item">
							<Link to="/contact" className={`nav-link ${setActive("/contact")}`}>Contact</Link>
						</li>
						<li className={"nav-item"}>
							<Link to="#" onClick={() => setShowPaymentModal(true)}
							      className={"btn btn_theme btn_md"}>
								Donate
							</Link>
						</li>
					</ul>
				</div>
			</div>
		);
	}, [setActive, showMobileMenu]);

	return (
		<header className="main_header_arae">
			<div className="topbar-area">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-8 col-md-12">
							<div className="top_bar_left_item_wrapper">
								<div className="top_bar_left_item">
									<div className="top_Bar_left_icon">
										<i className="fas fa-envelope"/>
									</div>
									<div className="top_Bar_left_text">
										<h5><Link to={`mailto:${config.appEmail}`}>{config.appEmail}</Link></h5>
									</div>
								</div>
								<div className="top_bar_left_item">
									<div className="top_Bar_left_icon">
										<i className="fas fa-phone"/>
									</div>
									<div className="top_Bar_left_text">
										<h5><Link to={`tel:${config.appPhone}`}>{config.appPhone}</Link></h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="navbar-area">
				<div className="main-responsive-nav">
					<div className="container">
						<div className="main-responsive-menu">
							<div className="logo">
								<Link to="/">
									<img src="/img/logo.png" alt="logo"/>
								</Link>
							</div>
					  </div>
				  </div>
			  </div>
			  <div className="main-navbar">
				  <div className="container">
					  <nav className="navbar navbar-expand-md navbar-light">
						  <Link className="navbar-brand" to="/">
							  <img src="/img/logo.png" alt="logo"/>
						  </Link>
						  <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
							  <ul className="navbar-nav">
								  <li className="nav-item">
									  <Link to="/" className={`nav-link ${setActive("/")}`}>Home</Link>
								  </li>
								  <li className="nav-item">
									  <Link to="/about" className={`nav-link ${setActive("/about")}`}>About us</Link>
								  </li>
								  <li className="nav-item">
									  <Link to="/how-to-adopt" className={`nav-link ${setActive("/how-to-adopt")}`}>How
										  to Adopt</Link>
								  </li>
								  {/*<li className="nav-item">
									  <Link to="#" className={`nav-link ${setActive("/available-dogs")} ${setActive("/how-to-adopt")}`}>
										  Adoption
										  <i className="fas fa-angle-down"/>
									  </Link>
									  <ul className="dropdown-menu">
										  <li className="nav-item">
											  <Link to="/available-dogs" className={`nav-link ${setActive("/available-dogs")}`}>Available Dogs</Link>
										  </li>
										  <li className="nav-item">
											  <Link to="/how-to-adopt" className={`nav-link ${setActive("/how-to-adopt")}`}>How to Adopt</Link>
										  </li>
									  </ul>
								  </li>*/}
								  <li className="nav-item">
									  <Link to="/gallery" className={`nav-link ${setActive("/gallery")}`}>Gallery</Link>
								  </li>
								  <li className="nav-item">
									  <Link to="/faqs" className={`nav-link ${setActive("/faqs")}`}>FAQs</Link>
								  </li>
								  <li className="nav-item">
									  <Link to="/stories" className={`nav-link ${setActive("/stories")}`}>Stories</Link>
								  </li>
								  {/*<li className="nav-item">
									  <Link to="/shop" className={`nav-link ${setActive("/shop")}`}>Shop</Link>
								  </li>*/}
								  <li className="nav-item">
									  <Link to="/contact" className={`nav-link ${setActive("/contact")}`}>Contact</Link>
								  </li>
							  </ul>
							  <div className="others-options d-flex align-items-center">
								  <div className="option-item">
									  <Link to="#" onClick={() => setShowPaymentModal(true)}
									        className={"btn btn_theme btn_md"}>
										  Donate
									  </Link>
								  </div>
							  </div>
						  </div>
					  </nav>
				  </div>
			  </div>
			  <div className="others-option-for-responsive">
				  <div className="container">
					  <div className="dot-menu" onClick={() => setShowMobileMenu(!showMobileMenu)}>
						  <div className="inner">
							  <div className="circle circle-one"></div>
							  <div className="circle circle-two"></div>
							  <div className="circle circle-three"></div>
						  </div>
					  </div>
					  <div className="container">
						  <div className="option-inner">
							  <div className="others-options">
								  <div className="responsive_icon_dot_flex">
									  <div className="option-item">
										  <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
										     aria-controls="offcanvasRight">
											  <img src="/img/icon/menu.png" alt="icon"/>
										  </Link>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
		    <MobileMenu/>
			<PaymentModal isOpen={showPaymentModal} onClose={() => setShowPaymentModal(false)}/>
	  </header>
  );
}
