import {Link} from "react-router-dom";

export const Home = () => {
	return (
		<>
			<section id="home_banner">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="main_banner_text_wrapper">
								<h1>The World Best
									Team For Pet
									Care <span>Services</span>
								</h1>
								<p>
									We are a vibrant community of problem-solvers, dog-lovers, and change-makers who
									believe every dog deserves a home and work hard every day for a kinder and more
									compassionate future.
								</p>
								<Link to="/about" className="btn btn_theme btn_md">Learn more</Link>
								<div className="banner_main_services">
									<div className="banner_main_service_item">
										<img src="/img/icon/banner-1.png" alt="img"/>
										<h5>Vet Service</h5>
									</div>
									<div className="banner_main_service_item">
										<img src="/img/icon/banner-2.png" alt="img"/>
										<h5>Special care</h5>
									</div>
									<div className="banner_main_service_item">
										<img src="/img/icon/banner-3.png" alt="img"/>
										<h5>Adoption</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="main_banner_img_left">
								<img src="/img/common/banner_left.png" alt="img" className="animation-img-one"/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="about_area" className="section_padding_bottom">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about_area_right_img">
								<img src="/img/common/about.png" alt="About Our Organization"/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about_area_left_wrapper">
								<div className="about_left_content_top">
									<h2>Dedicated to Your Pet's Wellbeing</h2>
									<p>
										Our mission is to provide outstanding care and services to animals in need. We
										strive to find loving homes for abandoned or rescued pets and offer
										comprehensive support to new pet owners. Every animal deserves a chance at a
										happy life.
									</p>
									<div className="about_round_check_wrapper">
										<div className="about_round_check_item">
											<div className="about_round_check_inner">
												<img src="/img/icon/round-check.png" alt="icon"/>
												<p>Certified Groomers</p>
											</div>
											<div className="about_round_check_inner">
												<img src="/img/icon/round-check.png" alt="icon"/>
												<p>Comprehensive Pet Care</p>
											</div>
										</div>
										<div className="about_round_check_item">
											<div className="about_round_check_inner">
												<img src="/img/icon/round-check.png" alt="icon"/>
												<p>Committed Animal Advocates</p>
											</div>
											<div className="about_round_check_inner">
												<img src="/img/icon/round-check.png" alt="icon"/>
												<p>Responsible Pet Adoption</p>
											</div>
										</div>
									</div>
								</div>
								<div className="about_left_content_bottom">
									<div className="accordion" id="accordionExample">
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingOne">
												<button className="accordion-button" type="button"
												        data-bs-toggle="collapse"
												        data-bs-target="#collapseOne" aria-expanded="true"
												        aria-controls="collapseOne">
													Why Adopt from Us?
												</button>
											</h2>
											<div id="collapseOne" className="accordion-collapse collapse show"
											     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
												<div className="accordion-body">
													<p>
														Adopting from us means you’re giving a dog a second chance at
														life. Our thorough assessment and matching process ensures each
														pet finds the perfect home. Learn more about the benefits of
														adopting a rescue pet.
													</p>
												</div>
											</div>
										</div>
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingTwo">
												<button className="accordion-button collapsed" type="button"
												        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
												        aria-expanded="false" aria-controls="collapseTwo">
													Our Services
												</button>
											</h2>
											<div id="collapseTwo" className="accordion-collapse collapse"
											     aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
												<div className="accordion-body">
													<p>
														We offer a variety of services including medical care, training
														sessions, and grooming—all tailored to your pet’s needs. Our
														dedicated team is here to support every aspect of pet ownership.
													</p>
												</div>
											</div>
										</div>
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingThree">
												<button className="accordion-button collapsed" type="button"
												        data-bs-toggle="collapse" data-bs-target="#collapseThree"
												        aria-expanded="false" aria-controls="collapseThree">
													Volunteer and Help
												</button>
											</h2>
											<div id="collapseThree" className="accordion-collapse collapse"
											     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
												<div className="accordion-body">
													<p>
														Join our community of volunteers and make a difference in the
														lives of animals. Whether it's fostering, helping at events, or
														administrative support, there’s a role for everyone who wants to
														help.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="our_gallery_area" className="section_padding_bottom">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 offset-lg-3">
							<div className="section_heading">
								<h2>Our gallery</h2>
								<p>Explore a collection of heartwarming photos featuring the dogs we've helped find
									forever homes. Each picture tells a story of hope and love.</p>
							</div>

						</div>
					</div>
					<div className="row popup-gallery">
						<div className="col-lg-4 col-md-12 col-sm-12 col-12">
							<div className="gallery_item_wrapper">
								<img src="/img/gallery/gallery-1.png" className="main_gallery_img" alt="img"/>
								<div className="gallery_item_inner_content">
									<div className="gallery_inner_text">
										<h3>View Image</h3>
										<Link to="/img/gallery/gallery-1.png"><img
											src="/img/icon/gallery_icon.png" alt="icon"/></Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 col-12">
							<div className="row">
								<div className="col-lg-6 col-md-6">
									<div className="gallery_item_wrapper">
										<img src="/img/gallery/gallery-2.png" className="main_gallery_img" alt="img"/>
										<div className="gallery_item_inner_content">
											<div className="gallery_inner_text">
												<h3>View Image</h3>
												<Link to="/img/gallery/gallery-2.png"><img
													src="/img/icon/gallery_icon.png" alt="icon"/></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="gallery_item_wrapper">
										<img src="/img/gallery/gallery-3.png" className="main_gallery_img" alt="img"/>
										<div className="gallery_item_inner_content">
											<div className="gallery_inner_text">
												<h3>View Image</h3>
												<Link to="/img/gallery/gallery-3.png"><img
													src="/img/icon/gallery_icon.png" alt="icon"/></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-12 col-md-12">
									<div className="gallery_item_wrapper">
										<img src="/img/gallery/gallery-4.png" className="main_gallery_img" alt="img"/>
										<div className="gallery_item_inner_content">
											<div className="gallery_inner_text">
												<h3>View Image</h3>
												<Link to="/img/gallery/gallery-4.png"><img
													src="/img/icon/gallery_icon.png" alt="icon"/></Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 col-12">
							<div className="gallery_item_wrapper">
								<img src="/img/gallery/gallery-5.png" className="main_gallery_img" alt="img"/>
								<div className="gallery_item_inner_content">
									<div className="gallery_inner_text">
										<h3>View Image</h3>
										<Link to="/img/gallery/gallery-5.png"><img
											src="/img/icon/gallery_icon.png" alt="icon"/></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
