import {Breadcrumbs} from "../layout/breadcrumbs";

export const HowToAdopt = () => {
  return (
	  <>
		  <Breadcrumbs page={"How To Adopt"}/>
		  <section id="main_service_details" className="section_padding">
			  <div className="container">
				  <div className="row">
					  <div className="col-lg-9">
						  <div className="service_details_left_wrapper">
							  <div className="service_details_left_top">
								  <h2>How to Adopt</h2>
								  <p>Are you ready to welcome a new member into your family? Learn more about the
									  adoption process and how you can provide a loving home to a pet in need.</p>
							  </div>
							  <div className="service_details_left_bottom">
								  <div className="row">
									  <div className="col-lg-7">
										  <div className="service_details_content">
											  <p>Adopting a pet is a rewarding experience, but it also comes with
												  responsibilities. Find out what steps you need to take to ensure a
												  smooth transition for your new furry friend.</p>
											  <p>Our adoption process includes:</p>
											  <ul>
												  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Researching different
													  pet breeds and their care requirements.
												  </li>
												  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Visiting our shelter
													  to meet the pets available for adoption.
												  </li>
												  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Completing an adoption
													  application and interview.
												  </li>
												  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Preparing your home
													  for the new arrival.
												  </li>
												  <li><img src="/img/icon/sm-leg.png" alt="Step"/>Welcoming your new pet
													  into your family!
												  </li>
											  </ul>
										  </div>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
	  </>
  );
}
